.modal-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 15px 15px 0px 0px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  border-radius: 3px;
  border-color: lightgray;
  box-shadow: none;
  outline: 0 none;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(40, 165, 203, 0.8);
  box-shadow: none;
  outline: 0 none;
}

.dropdown-toggle {
  text-decoration: none;
  border-bottom: none;
}

.pm-card:hover {
  cursor: pointer;
}

.pm-card {
  max-width: 500px;
}

.my-modal {
  border-radius: 20px;
}

/* Rounded corners for all react-bootstrap tables */
.table-responsive .table {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dee2e6;
}

/* Ensure table header maintains styling */
.table-responsive .table thead th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

/* Handle bordered table variant */
.table-responsive .table-bordered {
  border: 1px solid #dee2e6;
}

/* Button hover effects */
.btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

/* Specific hover effects for Bootstrap button variants */
.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-success:hover {
  background-color: #218838;
}

.btn-danger:hover {
  background-color: #c82333;
}

.btn-warning:hover {
  background-color: #e0a800;
}

.btn-info:hover {
  background-color: #138496;
}

/* Tab styling */
.nav-link,
.nav-tabs .nav-link {
  text-decoration: none !important;
  pointer-events: auto;
}

.nav-link.active,
.nav-tabs .nav-link.active {
  text-decoration: none !important;
  pointer-events: none;
}

/* Ensure no hover effects on active tabs */
.nav-link.active:hover,
.nav-tabs .nav-link.active:hover,
.nav-item .active:hover,
a.active:hover {
  text-decoration: none !important;
  pointer-events: none;
  cursor: default;
}

/* Override any Bootstrap default underlines */
.nav a,
.nav-tabs a,
.nav-item a {
  text-decoration: none !important;
}
