@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

body {
  margin: 0;
  font-family: 'Josefin Sans', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--company-color-bg);
  min-height: 100vh;
}

#root, .App {
  background: none;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (min-width: 600px) {
  .companylogo {
    max-width: 260px;
    max-height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .companylogo {
    max-width: 140px;
    max-height: 80px;
  }
}
